import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import FirstScreenSection from '../../components/FirstScreenSection';
import GradesSection from '../../components/GradesSection';
import Calculator from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import OurFeaturesSection from '../../components/OurFeaturesSection';
import {
  SeoTextSection,
  SeoTextHeading,
  SeoTextContent,
} from '../../components/SeoText';
import CTA from '../../components/CTA';

const HighSchoolWritingServicePage = () => (
  <Layout
    pageWrapperClassName="high-school-writing-service-page"
    headerClassName="header_white-bg"
  >
    <Helmet
      title="High School Essay Writing Services to Make Your Life Easier | TakeAwayEssay.com"
      meta={[
        {
          name: 'description',
          content:
            'Get to know the main dividends you can get if you hire any high school essay writer from our custom writing service. Place an order right now to handle your issues perfectly.',
        },

        {
          property: 'og:title',
          content:
            'Custom High School Essay Writing Service: Embrace Your School Time | TakeAwayEssay.com',
        },
        {
          property: 'og:description',
          content:
            'Our reliable custom high school essay writing service can make your high-school years less stressful and more successful. We offer affordable pricing, friendly support, and a wide range of writing work. Read this page to find out more about the service and place your first order!',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:image',
          content: 'https://takeawayessay.com/open-graph-img.jpg',
        },
      ]}
      link={[
        {
          rel: 'canonical',
          href: 'https://takeawayessay.com/high-school-writing-service.html',
        },
      ]}
    />

    <FirstScreenSection containerClassName="first-screen-section--seo">
      <h2 className="first-screen-section__title first-screen-section__title_seo">
        Does your high school teacher
        <br className="first-screen-section__title__br" /> want an essay from
        you?
      </h2>
      <p className="first-screen-section__heading first-screen-section__heading_seo">
        Submit a jaw-dropping college-level paper instead,
        <br className="first-screen-section__heading__br" /> and get an “A+”.
        Hire us!
      </p>
    </FirstScreenSection>

    <GradesSection />
    <Calculator />
    <Testimonials nameOfPageArray="home" />
    <OurFeaturesSection />

    <SeoTextSection>
      <SeoTextHeading>
        High school essay writing services to make your study life much easier
      </SeoTextHeading>

      <SeoTextContent>
        <div className="seo-text-section__text__css-half-column">
          <p>
            The process of learning is a long and complicated process. Many
            youngsters cannot withstand the pressure and drop out of schools and
            colleges. They cannot cope with tons of academic tasks and duties
            with strict demands and short deadlines. Many high schoolers require
            professional help to have a positive academic rating and not to go
            crazy. This is when TakeAwayEssay.com comes to their aid.
          </p>
          <p>
            Our Custom High School Essays Writing Service is one of the best in
            the USA and the rest of the world. Our experience counts over 10
            years and our rating is 9.8 out of 10 possible. These are impressive
            indicators that show that we can be trusted. What exactly do we
            propose? Read on to find that out.
          </p>
          <h2 className="h3">
            Professional high school essay writing services for smart learners
          </h2>
          <p>
            When a high schooler requires pro help, he or she commonly visits
            our site. Why is there such a tendency kept? Well, we are a
            respectful platform with the highest average rating because
            everything we propose is of the best quality and is aimed at
            customer satisfaction. Every high school essay writer you meet on
            our site is an educated, talented, and diligent performer.
          </p>
          <p>
            We are very cautious when we seek high school essay writers. None of
            them is hired just because of good grades at college or some
            impressive recommendations. We check all candidates the same and put
            them into various entry tests. Only after they cope with them
            successfully, we hire them. Afterward, experienced mentors keep an
            eye on their progress. This is how we are confident that all our
            writers are able to meet the top standards of all educational
            institutions.
          </p>
          <p>
            They treat every client with due respect and take into account his
            or her wishes and whims. In other words, we treat every order
            individually to reach the maximum satisfaction of our dear clients.
            The academic arsenal of our experts is vast and can satisfy any
            demand you may need. The main ones are as follows:
          </p>
          <h3 className="h3">Any subject</h3>
          <p>
            We have 500+ experts. They are specialists in various academic
            subjects. It means you can find specialists in literature, history,
            English, music, art, medicine, exact sciences, IT, and other areas.
            Your choice will never be limited!
          </p>
          <h3 className="h3">Any skill</h3>
          <p>
            Our specialists possess various skills to tackle your order to your
            liking. There are various things they can do for you. The main ones
            are:
          </p>
          <ul className="dot-list">
            <li>Writing and rewriting.</li>
            <li>Editing and proofreading;</li>
            <li>Citing and referencing;</li>
            <li>Researching and outlining;</li>
            <li>Generating topics;</li>
          </ul>
          <p>
            You are welcome to contact your helper directly, which is a great
            advantage for anyone. Thus, you can learn the latest news on the
            progress of your order. Just set a reasonable schedule to get in
            touch when both of you have time to do that.
          </p>
          <h3 className="h3">Any assignment</h3>
          <p>
            Our experts can fulfill more than essays. They likewise specialize
            in fulfilling case studies, lab reports, book reviews, term papers,
            resumes, admission letters, and so on. It means they can handle
            projects for colleges and universities as well!
          </p>
          <h2 className="h3">
            We write high school essays for money, but don’t charge much
          </h2>
          <p>
            You probably know that custom writing aid of any form has its price.
            It’s not gratis and some of your savings must be spent. The price
            policy of any custom platform means a lot to students with limited
            budgets. We know that most learners do not have too much money, so
            we propose relatively cheap prices to make our aid affordable.
          </p>
          <p>
            To define how much must be paid, you need to fill out an application
            form. It contains the main features of your paper:
          </p>
          <ul className="dot-list">
            <li>Quality.</li>
            <li>Type.</li>
            <li>Deadline.</li>
            <li>Size.</li>
          </ul>
          <p>
            An online calculator will show the total cost. If it exceeds your
            current budget, feel free to alter any field in the application.
            Every change can lower the cost. You should also know that the
            status of an essay writer high school on our platform impacts the
            cost as well. Be attentive when you negotiate with a writer to be
            sure you get what you want and never overpay. We create all the
            necessary conditions for this goal.
          </p>
          <h2 className="h3">Swift aid for active learners</h2>
          <p>
            If you are a student, you surely know that time is very valuable, as
            well as an elusive thing you can use. Not all people are good at
            planning and using their time effectively. Many students cannot
            always reach their deadlines, so they sometimes fail.
          </p>
          <h2 className="h3">What Else Do We Offer?</h2>
          <p>
            As you are a modern person who knows a lot about how various service
            should treat their clients, you realize that you are supposed to
            enjoy more benefits. You are 100% correct and we are happy to
            provide the required conditions. These are as follows:
          </p>
          <ul className="dot-list">
            <li>
              24/7 Customer Support
              <p>
                Oftentimes, students receive tasks all of a sudden or remember
                some of them at the “last moment” when the night is deep. Such
                situations could be referred to as catastrophes. Luckily, you
                have us!
              </p>
            </li>
            <li>
              Projects Done from Scratch
              <p>
                The papers we release are free of all kinds of plagiarism. We
                use the best anti-plagiarism software to detect non-unique
                elements in your texts. If any of them are detected, we will
                surely remove them all from them.
              </p>
            </li>
            <li>
              Full Privacy
              <p>
                We are a trustworthy writing site that keeps an eye on the
                privacy of its clients. We use the most effective antivirus
                software, which is maintained regularly. Thus, it protects our
                databases from the newest cyber dangers. No hacker will ever get
                through our firewall.
              </p>
            </li>
            <li>
              Monetary Compensation
              <p>
                We are an honest company that never lets down its clients.
                Therefore, we ensure a return policy. Once your solver accepts
                your terms, he or she is obliged to complete them as promised.
                If it doesn’t happen, we will return your money to the fullest.
              </p>
            </li>
          </ul>
          <p>
            In case you have problems with your academic tasks, feel free to
            turn to us. TakeAwayEssay.com is always happy to provide its dear
            clients with everything they may need to enjoy academic success.
            Place an order now, and we promise to complete a perfect piece of
            writing for you.
          </p>
        </div>
      </SeoTextContent>
    </SeoTextSection>

    <CTA btnText="Buy paper">
      Ever wondered how being
      <br /> an academic superstar feels like?
    </CTA>
  </Layout>
);

export default HighSchoolWritingServicePage;
